import clantonPark1 from "./images/projects/clantonPark/RosenWeb11.jpg"
import clantonPark3 from "./images/projects/clantonPark/RosenWeb3.jpg"
import clantonPark4 from "./images/projects/clantonPark/RosenWeb4.jpg"
import clantonPark5 from "./images/projects/clantonPark/RosenWeb5.jpg"
import clantonPark2 from "./images/projects/clantonPark/RosenWeb2.jpg"
import clantonPark12 from "./images/projects/clantonPark/RosenWeb12.jpg"

import bareMarket1 from "./images/projects/bareMarket/Bare_Market-48.jpg"
import bareMarket2 from "./images/projects/bareMarket/Bare_Market-89.jpg"
import bareMarket3 from "./images/projects/bareMarket/Bare_Market-90.jpg"
import bareMarket4 from "./images/projects/bareMarket/Bare_Market-1.jpg"
import bareMarket5 from "./images/projects/bareMarket/Bare_Market-4.jpg"

import bareMarket6 from "./images/projects/bareMarket/Bare_Market-87.jpg"
import bareMarket7 from "./images/projects/bareMarket/Bare_Market-91-thin.jpg"
import bareMarket8 from "./images/projects/bareMarket/Bare_Market-56.jpg"
import bareMarket9 from "./images/projects/bareMarket/Bare_Market-58.jpg"

import theBeachMotel1 from "./images/projects/theBeachMotel/theBeachMotel1.jpg"
import theBeachMotel2 from "./images/projects/theBeachMotel/theBeachMotel2.jpg"

import theBeachMotel5 from "./images/projects/theBeachMotel/theBeachMotel5.jpg"
import theBeachMotel6 from "./images/projects/theBeachMotel/theBeachMotel6.jpg"
import theBeachMotel7 from "./images/projects/theBeachMotel/theBeachMotel7.jpg"
import theBeachMotel8 from "./images/projects/theBeachMotel/theBeachMotel8.jpg"
import theBeachMotel9 from "./images/projects/theBeachMotel/theBeachMotel9.jpg"
import theBeachMotel10 from "./images/projects/theBeachMotel/theBeachMotel10.jpg"
import theBeachMotel11 from "./images/projects/theBeachMotel/theBeachMotel11.jpg"
import theBeachMotel12 from "./images/projects/theBeachMotel/theBeachMotel12.jpg"
import theBeachMotel13 from "./images/projects/theBeachMotel/portrait/theBeachMotel13.jpg"
import theBeachMotel14 from "./images/projects/theBeachMotel/portrait/theBeachMotel14.jpg"
import theBeachMotel15 from "./images/projects/theBeachMotel/portrait/theBeachMotel15.jpg"

import bala2 from "./images/projects/bala/bala2.jpg"
import bala3 from "./images/projects/bala/bala3.jpg"
import bala4 from "./images/projects/bala/bala4.jpg"
import bala5 from "./images/projects/bala/bala5.jpg"
import bala6 from "./images/projects/bala/bala6.jpg"
import bala7 from "./images/projects/bala/bala7.jpg"
import bala8 from "./images/projects/bala/bala8.jpg"
import bala9 from "./images/projects/bala/bala9.jpg"
import bala10 from "./images/projects/bala/bala10.jpg"
import bala11 from "./images/projects/bala/bala11.jpg"
import bala12 from "./images/projects/bala/bala12.jpg"
import bala13 from "./images/projects/bala/bala13.jpg"
import bala14 from "./images/projects/bala/bala14.jpg"

import bala17 from "./images/projects/bala/bala17.jpg"
import bala18 from "./images/projects/bala/bala18.jpg"

import bala19 from "./images/projects/bala/bala19.jpg"

import leslieville1 from "./images/projects/leslieville/leslieville1.jpg"
import leslieville3 from "./images/projects/leslieville/leslieville3.jpg"
import leslieville7 from "./images/projects/leslieville/leslieville7.jpg"
import leslieville8 from "./images/projects/leslieville/leslieville8.jpg"
import leslieville10 from "./images/projects/leslieville/leslieville10.jpg"
import leslieville11 from "./images/projects/leslieville/leslieville11.jpg"
import leslieville12 from "./images/projects/leslieville/leslieville12.jpg"
import leslieville13 from "./images/projects/leslieville/leslieville13.jpg"
import leslieville14 from "./images/projects/leslieville/leslieville14.jpg"

import annex1 from "./images/projects/annex/annex1.jpg"
import annex2 from "./images/projects/annex/annex2.jpg"
import annex3 from "./images/projects/annex/annex3.jpg"
import annex4 from "./images/projects/annex/annex4.jpg"
import annex5 from "./images/projects/annex/annex5.jpg"
import annex6 from "./images/projects/annex/annex6.jpg"
import annex7 from "./images/projects/annex/annex7.jpg"
import annex8 from "./images/projects/annex/annex8.jpg"
import annex9 from "./images/projects/annex/annex9.jpg"
import annex10 from "./images/projects/annex/annex10.jpg"
import annex11 from "./images/projects/annex/annex11.jpg"
import annex12 from "./images/projects/annex/annex12.jpg"
import annex13 from "./images/projects/annex/annex13.jpg"
import annex14 from "./images/projects/annex/annex14.jpg"
import annex15 from "./images/projects/annex/annex15.jpg"
import annex16 from "./images/projects/annex/annex16.jpg"
import annex17 from "./images/projects/annex/annex17.jpg"
import annex18 from "./images/projects/annex/annex18.jpg"
import annex19 from "./images/projects/annex/annex19.jpg"
import annex20 from "./images/projects/annex/annex20.jpg"
import annex21 from "./images/projects/annex/annex21.jpg"
import annex22 from "./images/projects/annex/annex22.jpg"
import annex23 from "./images/projects/annex/annex23.jpg"
import annex24 from "./images/projects/annex/annex24.jpg"
import annex25 from "./images/projects/annex/annex25.jpg"

import bloomfield1 from "./images/projects/bloomfield/bloomfield1.jpg"
import bloomfield2 from "./images/projects/bloomfield/bloomfield2.jpg"
import bloomfield3 from "./images/projects/bloomfield/bloomfield3.jpg"
import bloomfield4 from "./images/projects/bloomfield/bloomfield4.jpg"
import bloomfield5 from "./images/projects/bloomfield/bloomfield5.jpg"
import bloomfield6 from "./images/projects/bloomfield/bloomfield6.jpg"
import bloomfield7 from "./images/projects/bloomfield/bloomfield7.jpg"
import bloomfield8 from "./images/projects/bloomfield/bloomfield8.jpg"
import bloomfield9 from "./images/projects/bloomfield/bloomfield9.jpg"

import briarhill1 from "./images/projects/briarhill/briarhill1.jpg"
import briarhill2 from "./images/projects/briarhill/briarhill2.jpg"
import briarhill3 from "./images/projects/briarhill/briarhill3.jpg"
import briarhill4 from "./images/projects/briarhill/briarhill4.jpg"
import briarhill5 from "./images/projects/briarhill/briarhill5.jpg"
import briarhill6 from "./images/projects/briarhill/briarhill6.jpg"
import briarhill7 from "./images/projects/briarhill/briarhill7.jpg"
import briarhill7a from "./images/projects/briarhill/briarhill7a.jpeg"

import briarhill8 from "./images/projects/briarhill/briarhill8.jpg"
import briarhill9 from "./images/projects/briarhill/briarhill9.jpg"
import briarhill11 from "./images/projects/briarhill/briarhill11.jpg"
import briarhill12 from "./images/projects/briarhill/briarhill12.jpg"
import briarhill13 from "./images/projects/briarhill/briarhill13.jpg"
import briarhill14 from "./images/projects/briarhill/briarhill14.jpg"

import woodside1 from "./images/projects/woodside/woodside1.jpg"
import woodside2 from "./images/projects/woodside/woodside2.jpg"
import woodside3 from "./images/projects/woodside/woodside3.jpg"
import woodside4 from "./images/projects/woodside/woodside4.jpg"
import woodside5 from "./images/projects/woodside/woodside5.jpg"
import woodside6 from "./images/projects/woodside/woodside6.jpg"
import woodside7 from "./images/projects/woodside/woodside7.jpg"
import woodside8 from "./images/projects/woodside/woodside8.jpg"
import woodside9 from "./images/projects/woodside/woodside9.jpg"
import woodside10 from "./images/projects/woodside/woodside10.jpg"
import woodside11 from "./images/projects/woodside/woodside11.jpg"
import woodside12 from "./images/projects/woodside/woodside12.jpg"
import woodside13 from "./images/projects/woodside/woodside13.jpg"

import dalemount1 from "./images/projects/dalemount/dalemount1.jpg"
import dalemount2 from "./images/projects/dalemount/dalemount2.jpg"
import dalemount3 from "./images/projects/dalemount/dalemount3.jpg"
import dalemount4 from "./images/projects/dalemount/dalemount4.jpg"
import dalemount5 from "./images/projects/dalemount/dalemount5.jpg"
import dalemount6 from "./images/projects/dalemount/dalemount6.jpg"
import dalemount7 from "./images/projects/dalemount/dalemount7.jpg"
import dalemount8 from "./images/projects/dalemount/dalemount8.jpg"
import dalemount9 from "./images/projects/dalemount/dalemount9.jpg"
import dalemount10 from "./images/projects/dalemount/dalemount10.jpg"
import dalemount11 from "./images/projects/dalemount/dalemount11.jpg"
import dalemount12 from "./images/projects/dalemount/dalemount12.jpg"
import dalemount13 from "./images/projects/dalemount/dalemount13.jpg"
import dalemount14 from "./images/projects/dalemount/dalemount14.jpg"

const projects = {
    theBeachMotel: {
        name: "The Beach Motel",
        key: "thebeachmotel",
        descr: `The historic lake culture of Southampton, Ontario provided 
        the source of inspiration for the master plan + design development of 
        this vintage motel property.  Near the sandy shores of Lake Huron, the lobby 
        features gentle curves + new oversized glazing walls reminiscent of local lighthouse 
        architectural forms. At night it creates a warm glow, providing an inviting beacon for 
        guests at the end of their journey. The signature blues + greens of the Bruce County landscape 
        are applied throughout in shades ranging from sky blue at the lounge ceiling to earthy greens in 
        the guest rooms. Natural textures + materials are paired with Canadian design to create a truly boutique 
        lakeside hospitality retreat honouring the charm + community of this wonderful town.`,
        mainImage: theBeachMotel7,
        altImage: theBeachMotel2,
        layouts: [
            {
                type: "fullbleed",
                animate: true,
                images: [theBeachMotel1],
            },
            {
                type: "duobleed",
                flip: false,
                animate: false,
                images: [theBeachMotel9, theBeachMotel13],
                marginBottom: true,
            },
            {
                type: "fullbleed",
                animate: false,
                marginBottom: true,
                images: [theBeachMotel2],
            },
            {
                type: "fullbleed",
                animate: false,
                marginBottom: true,

                images: [theBeachMotel5],
            },

            {
                type: "fullbleed",
                animate: false,
                marginBottom: true,
                images: [theBeachMotel6],
            },
            {
                type: "fullbleed",
                animate: true,
                marginBottom: true,
                images: [theBeachMotel7],
            },
            {
                type: "fullbleed",
                animate: false,
                images: [theBeachMotel8],
                marginBottom: false,
            },

            {
                type: "duobleed",
                flip: true,
                animate: false,
                images: [theBeachMotel14, theBeachMotel10],
            },
        ],
    },

    dalemount: {
        name: "Dalemount",
        key: "dalemount",
        descr: `Tucked into a quiet bend, this Toronto house underwent a full gut renovation + addition to elevate + expand the interior space. Drawing inspiration from old world Europe, the design team explored classic architectural forms partnered with sophisticated colours + subtle textures. Thoughtful millwork, custom furniture, + dramatic sightlines define this chic family home as the new classic.`,
        mainImage: dalemount13,
        altImage: dalemount10,
        layouts: [
            {
                type: "fullbleed",
                animate: true,
                scale: 0.87,
                images: [dalemount13],
            },
            {
                type: "columns",
                animate: true,
                images: [dalemount9, dalemount4, dalemount3],
                marginBottom: false,
            },
            {
                type: "duobleed",
                flip: true,
                animate: false,
                images: [dalemount1, dalemount10],
                marginBottom: false,
            },

            {
                type: "columns",
                animate: true,
                images: [dalemount12, dalemount8, dalemount2],
                marginBottom: true,
            },
        ],
    },

    woodside: {
        name: "Woodside",
        key: "woodside",
        descr: `In exploring + defining a design direction for our clients, we took the time to listen + better understand their family culture. Petit zones were developed to support the daily routines + habits of each family member, from morning coffee to family dinner. Our team evolved the layout with thoughtful custom millwork, leaning into a palette which celebrates natural textures, handcrafted details + modern moments.`,
        mainImage: woodside1,
        altImage: woodside10,
        layouts: [
            {
                type: "fullbleed",
                animate: true,
                images: [woodside1],
            },
            {
                type: "duobleed",
                flip: false,
                animate: false,
                images: [woodside10, woodside11],
                marginBottom: false,
            },
            {
                type: "columns",
                animate: true,
                images: [woodside5, woodside8, woodside9],
                marginBottom: true,
            },
            {
                type: "fullbleed",
                animate: false,
                marginBottom: true,

                images: [woodside13],
            },
        ],
    },

    bala: {
        name: "Bala Cottage",
        key: "bala",
        descr: `Situated along the shore of Moon River, 
        surrounded by the forest + Canadian shield, 
        this comprehensive cottage renovation becomes an 
        interior extension of the outdoors.  Dark greens, 
        watery blues + warm woods build a palette punctuated 
        with unexpected, locally crafted details which our studio 
        is known for. Each space feels welcoming + 
        relaxed with elevated moments of luxury, creating a 
        timeless cottage country retreat for decades to come.`,
        mainImage: bala14,
        layouts: [
            { type: "fullbleed", animate: false, images: [bala14] },
            {
                type: "duobleed",
                flip: false,
                animate: false,
                images: [bala3, bala6],
            },
            {
                type: "columns",
                animate: true,
                images: [bala4, bala5, bala2],
            },

            {
                type: "columns",
                animate: false,
                images: [bala7, bala8, bala9],
            },
            {
                type: "columns",
                animate: false,
                images: [bala10, bala11, bala18],
            },
            {
                type: "columns",
                animate: false,
                images: [bala17, bala12, bala13],
                marginBottom: true,
            },
            { type: "fullbleed", animate: false, images: [bala19] },
        ],
    },
    bareMarket: {
        name: "Bare Market",
        key: "baremarket",
        descr: `Collaborating with our client on their first brick and mortar 
        location in the Danforth Village neighbourhood, we were presented with 
        the opportunity to cultivate community through a built environment. 
        The planning for this package-free grocery store features flexible custom 
        retail fixturing, a cafe for gathering and multi-purpose furniture to suit 
        pop-up events & workshops. Sustainable materials, re-furbished fixtures
        and a green construction process created a design which celebrates 
        our client’s ethos & brand.`,
        mainImage: bareMarket1,
        layouts: [
            { type: "fullbleed", animate: true, images: [bareMarket2] },
            {
                type: "duobleed",
                flip: false,
                animate: false,
                images: [bareMarket5, bareMarket4],
            },
            {
                type: "duobleed",
                flip: false,
                animate: true,
                images: [bareMarket1, bareMarket9],
                marginBottom: true,
            },
            { type: "fullbleed", animate: false, images: [bareMarket3] },
        ],
    },

    leslieville: {
        name: "Leslieville",
        key: "Leslieville",
        descr: `In this sought-after neighbourhood of Toronto, the main floor of an 
        archetypical semi was reimagined. Custom cabinetry with modern lines were 
        crafted in an edited palette of cool grey blues + warm white oak. Defined 
        zones create a myriad of function for this young, hip family providing space 
        for work, cook + play. In a city with immense local design talent, feature 
        lighting + hardware were locally sourced, creating a space which celebrates 
        good design with a signature city style.
        `,
        mainImage: leslieville14,
        layouts: [
            { type: "fullbleed", animate: true, images: [leslieville14] },
            {
                type: "columns",
                animate: true,
                images: [leslieville1, leslieville3, leslieville12],
            },
            {
                type: "duobleed",
                flip: true,
                animate: false,
                images: [leslieville13, leslieville7],
            },
            {
                type: "columns",
                animate: false,
                images: [leslieville8, leslieville10, leslieville11],
            },
        ],
    },

    annex: {
        name: "The Annex",
        key: "annex",
        descr: `Built in the 1900s, this classic Toronto annex home had remained unchanged for decades. 
        Instead of gutting the interior we took a restorative approach to planning, materials, 
        + detailing to develop a wonderfully, modern home that embraces its victorian character. 
        To create a multi-generational space our team maintained the closed planning + designated livable 
        zones for each family member. Allowing us to strike a balance of function + charm 
        while creating cohesion between old and new. Leading to a wonderfully welcoming space for this 
        next generation family of city dwellers.
        `,
        mainImage: annex23,
        layouts: [
            {
                type: "columns",
                animate: false,
                images: [annex20, annex18],
            },
            {
                type: "columns",
                animate: false,
                images: [annex17, annex13, annex15],
            },
            /*
            {
                type: "threesplit",
                flip: false,
                images: [annex23, annex24, annex21, annex22, annex20],
            },
            */

            {
                type: "columns",
                animate: true,
                images: [annex23, annex12, annex11],
            },

            {
                type: "columns",
                images: [annex6, annex9, annex8],
            },
            {
                type: "columns",
                images: [annex1, annex3],
            },
        ],
    },
    bloomfield: {
        name: "Bloomfield",
        key: "bloomfield",
        descr: `Tucked into Leslieville’s tree lined street, Bloomfield is perfectly positioned 
        for a young family seeking city comforts. To improve connectivity for this main floor,
         our team expanded the kitchen to create better flow + sightlines with dining + living. 
         Embracing verticality, custom millwork solves storage needs at all levels from the 
         smallest to tallest person. Partnered with materials selected for their calm, natural beauty, 
         non-fuss durability + timelessness. Final furniture touches complement our client’s eclectic taste, 
         adding to their savvy mcm collection. `,
        mainImage: bloomfield1,
        layouts: [
            {
                type: "fullbleed",
                flip: false,
                images: [bloomfield1],
            },

            {
                type: "columns",
                animate: true,
                images: [bloomfield3, bloomfield5, bloomfield6],
            },

            {
                type: "columns",
                animate: false,
                images: [bloomfield8, bloomfield9, bloomfield4],
            },
        ],
    },
    briarhill: {
        name: "Briar Hill",
        key: "briarhill",
        descr: `Starting with a renovation, this vintage architype house 
        in the Fairbanks neighbourhood underwent a major transformation.
         To create modern amenities, the overall interior plan was adjusted to 
         elevate the kitchen + bathrooms while preserving living + bedroom area. 
         The new open concept kitchen is a spacious, family hub with 
         thoughtful storage + elevated material moments. It connects with the main entry, 
         outdoor veranda, living + dining rooms to support a family who loves 
         to entertain. While equal priority for daily routines was accounted for, 
         with an expanded kid’s bath + a new 3-piece ensuite for the primary bedroom. 
         Making a modern, family forward space for the next chapter of this unique Toronto home.
        `,
        mainImage: briarhill14,
        //compact: true,
        layouts: [
            {
                type: "duobleed",
                flip: true,
                animate: false,
                images: [briarhill14, briarhill7a],
            },
            {
                type: "columns",
                animate: false,

                images: [briarhill8, briarhill11, briarhill12],
            },

            {
                type: "columns",
                animate: true,
                images: [briarhill13, briarhill2, briarhill3],
            },
        ],
    },
}

export default projects
