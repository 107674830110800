import { Suspense, useEffect, useRef } from "react"
import "./App.css"
import Landing from "./components/Landing"
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
} from "react-router-dom"
import Project from "./components/Project"
import About from "./components/About"
import Contact from "./components/Contact"
import Loading from "./components/Loading"
import ReactGA from "react-ga"

const trackingId = "UA-187456920-1"
ReactGA.initialize(trackingId)

function App() {
    const viewportRef = useRef()

    function GA(props) {
        const location = useLocation()
        useEffect(() => {
            ReactGA.pageview(location.pathname)
        }, [location])
        return props.children
    }

    const S = (props) => {
        return <Suspense fallback={<Loading />}>{props.children}</Suspense>
    }

    return (
        <Router>
            <GA>
                <Routes>
                    <Route
                        path="/"
                        element={<Landing viewportRef={viewportRef} />}
                    />
                    <Route
                        path="/projects/:key"
                        element={
                            <S>
                                <Project suspend viewportRef={viewportRef} />
                            </S>
                        }
                    />
                    <Route
                        path="/about/"
                        element={
                            <S>
                                <About suspend viewportRef={viewportRef} />
                            </S>
                        }
                    />
                    <Route
                        path="/contact/"
                        element={<Contact viewportRef={viewportRef} />}
                    />
                </Routes>
            </GA>
        </Router>
    )
}

export default App
