/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useRef, useState } from "react"
import { css } from "@emotion/react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import config from "../config"
import useIsMobile from "../hooks/Mobile"

export function Dots(props) {
    const styles = {
        root: {
            display: "flex",
            flexDirection: props.horizontal ? "row" : "column",
            padding: config.margins.large,
        },
        dot: {
            marginTop: props.horizontal
                ? "inherit"
                : props.halfSize
                ? config.margins.large
                : "calc(" + config.margins.large + "*2)",
            marginLeft: !props.horizontal
                ? "inherit"
                : props.halfSize
                ? config.margins.large
                : "calc(" + config.margins.large + "*2)",
            height: props.halfSize
                ? config.margins.large
                : "calc(" + config.margins.large + "*2)",
            width: props.halfSize
                ? config.margins.large
                : "calc(" + config.margins.large + "*2)",
            backgroundColor: props.color,
            borderRadius: "50%",
            "&:first-child": {
                marginLeft: 0,
                marginTop: 0,
            },
        },
    }
    return (
        <div css={css(styles.root)}>
            <div css={css(styles.dot)}></div>
            <div css={css(styles.dot)}></div>
            <div css={css(styles.dot)}></div>
        </div>
    )
}

export default function FaceCard(props) {
    const isMobile = useIsMobile()
    const right = isMobile ? false : props.right
    const styles = {
        root: {
            display: "flex",
            flexDirection: "row",
            backgroundColor: props.backgroundColor,
            width: isMobile ? "100vw" : "50vw",
            alignItems: "center",
            justifyContent: "center",
            height: props.fit ? "auto" : "80%",
            fontFamily: "light",
            textAlign: "center",
            "& b": {
                fontFamily: "bold",
            },
        },
        content: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: isMobile ? "60%" : "50%",
            marginTop: "calc(" + config.margins.xlarge + " * 2)",
            marginBottom: "calc(" + config.margins.xlarge + " * 2)",
        },
        imageContainer: {
            borderRadius: "50%",
            width: "100%",
            height: "0",
            paddingBottom: "100%",
            overflow: "hidden",
            flexGrow: 1,
            marginBottom: "calc(" + config.margins.xlarge + " )",
        },
        image: {
            width: "100%",
            height: "auto",
        },
        font: {
            color: props.fontColor,
        },
    }

    return (
        <div css={css(styles.root)}>
            <div css={css(styles.content)}>
                <div css={css(styles.imageContainer)}>
                    <img
                        css={css(styles.image)}
                        src={props.face}
                        alt={props.name}
                    />
                </div>
                <div css={css(styles.font)}>{props.bio}</div>
            </div>
        </div>
    )
}
