/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react"
import config from "../config"
import { motion } from "framer-motion"
import useIsMobile from "../hooks/Mobile"
export default function Columns(props) {
    const isMobile = useIsMobile()
    const styles = {
        root: {
            display: "flex",
            height: "auto",
            width: "calc(100vw - " + config.margins.large + " * 2)",
            justifyContent: "space-evenly",
            gap: config.margins.large,
            margin: config.margins.large,
            marginBottom: 0,
        },
        container: {
            marginTop: 0,
            overflow: "hidden",
            minHeight: "20vh",
            marginBottom: props.marginBottom ? config.margins.large : 0,
        },
        image: {
            marginTop: 0,
            height: "auto",
            width: props.images.length === 2 ? "50vw" : "33vw",
            minHeight: "20vh",
            objectFit: "cover",
            verticalAlign: "top",
        },
    }

    const items = []
    let i = 0
    for (const img of props.images) {
        i++
        items.push(
            <div key={i} css={css(styles.container)}>
                <motion.img
                    initial={
                        props.animate
                            ? { opacity: 0, scale: 0.8, translateY: 100 }
                            : {}
                    }
                    whileInView={{ opacity: 1, scale: 1, translateY: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 1, delay: i / 4 }}
                    css={css(styles.image)}
                    src={img}
                    alt=""
                />
            </div>
        )
    }
    return <div css={css(styles.root)}>{items}</div>
}
