/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react"
import config from "../config"
import { motion } from "framer-motion"
import useIsMobile from "../hooks/Mobile"
import Image from "./Image"

export default function ThreeSplit(props) {
    const isMobile = useIsMobile()
    const styles = {
        root: {
            display: "flex",
            flexDirection: "row",
            height: "auto",
            width: "100vw",
            gap: isMobile ? config.margins.large : config.margins.large,
            marginTop: config.margins.large,
            marginBottom: 0,
            justifyContent: "flex-start",
            flexWrap: isMobile ? "wrap" : "none",
        },
        container: {
            height: isMobile ? "auto" : "95vh",
            marginTop: 0,
            overflow: "hidden",
            width: isMobile ? "100vw" : "33vw",
            marginBottom: props.marginBottom ? config.margins.large : 0,
            display: "flex",
            justifyContent: "center",
        },
        smallContainer: {
            height: "auto",
            overflow: "hidden",
        },
        hcontainer: {
            height: isMobile ? "auto" : "calc(95vh)",
            marginTop: 0,
            overflow: "hidden",
            width: isMobile
                ? "calc(50vw - " + config.margins.large + "/2)"
                : "33vw",
            marginBottom: props.marginBottom ? config.margins.large : 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: config.margins.large,

            "& img": {
                paddingBottom: "3",
            },
        },
        smallImage: {
            height: isMobile ? "55vw" : "48vh",
            minWidth: isMobile ? "50vw" : "25vw",
            verticalAlign: "top",
            objectFit: "cover",
        },

        caption: {
            height: isMobile ? "55vw" : "48vh",
            minWidth: isMobile ? "50vw" : "25vw",
            verticalAlign: "top",
            paddingRight: "10px",
        },
        first: {
            width: isMobile
                ? "100vw"
                : "calc(66.3vw + " + config.margins.large + ")",
            flexGrow: 2,
            marginBottom: isMobile ? 0 : 0,
        },

        image: {
            height: isMobile
                ? "auto"
                : "calc(100vh - " + config.margins.large + " * 2)",
            width: isMobile ? "100vw" : "50vw",
            verticalAlign: "top",
            objectFit: "cover",
        },
    }

    const items = []
    let i = 0

    const img = props.images[0]
    i++

    return (
        <div css={css(styles.root)}>
            <FlipItem styles={styles} index={i} img={img} flip={props.flip} />

            <div css={css(styles.hcontainer)}>
                <FlipItem
                    styles={styles}
                    index={2}
                    img={props.images[1]}
                    flip={props.flip}
                    text={props.text}
                />

                <FlipItem
                    styles={styles}
                    index={2}
                    img={props.images[2]}
                    flip={props.flip}
                />
            </div>

            <div css={css(styles.hcontainer)}>
                <FlipItem
                    styles={styles}
                    index={2}
                    img={props.images[3]}
                    flip={props.flip}
                />

                <FlipItem
                    styles={styles}
                    index={2}
                    img={props.images[4]}
                    flip={props.flip}
                />
            </div>
        </div>
    )
}

function FlipItem(props) {
    const styles = props.styles
    const i = props.index
    const flipKey = props.flip ? 2 : 1
    const img = props.img

    return (
        <div
            key={i}
            css={
                i === flipKey
                    ? css(styles.container, styles.first)
                    : css(styles.smallContainer)
            }
        >
            <motion.div
                initial={
                    props.animate
                        ? { opacity: 0, scale: 0.8, translateY: 100 }
                        : {}
                }
                whileInView={{ opacity: 1, scale: 1, translateY: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: i / 4 }}
                alt=""
            >
                {props.text && (
                    <div css={css(styles.caption)}>{props.text}</div>
                )}
                {!props.text && (
                    <Image
                        suspend={props.suspend}
                        css={css(
                            i === flipKey ? styles.image : styles.smallImage
                        )}
                        src={img}
                        alt="full"
                    />
                )}
            </motion.div>
        </div>
    )
}
