import introImage from "./images/about/About.jpg"
import fillerImage from "./images/about/About2.jpg"

import npk from "./images/about/natashapk.jpg"
const about = {
    introImage: introImage,
    fillerImage: fillerImage,
    firstHit: <></>,
    blurb: (
        <>
            <p>
                Common good studio is an award winning Toronto based interior
                design team founded on 15 years of Canadian industry experience.
            </p>
            <p>
                With a client-centric approach, our studio team applies a modern
                design process to navigate + actualize signature interior spaces
                for our clients.
            </p>
            <p>
                Through a distinctly local point of view, projects are
                positioned to tell a story, cultivate a memorable experience, +
                reflect a sense of place. We partner with trades, makers +
                artisans, we explore a client’s vision to achieve unique,
                memorable, + sustainably minded interior projects.
            </p>
            <p>
                {" "}
                Our collaborative approach is engaging, innovative + detailed,
                building confidence with clients + project teams to actualize a
                project from an initial concept through to project completion.
            </p>
        </>
    ),
    team: [
        {
            headshot: npk,
            name: "Natasha Popek-Konieczko",
            bio: (
                <>
                    <p>
                        <b>Natasha Popek-Konieczko </b>
                        has established a reputation that highlights her
                        innovative drive and adaptable leadership. She promotes
                        a culture of positivity, offering thoughtful insight and
                        considerate solutions to projects, colleagues and the
                        studio team.
                    </p>
                    <p>
                        It is through this lens that she is successfully able to
                        realize a client's vision and construct award-winning
                        projects. Her diverse experience ranges from complex
                        high-rise residential developments in Canada and the US
                        to hospitality and retail environments.
                    </p>
                    <p>
                        Her accountability extends throughout the design process
                        creating value at every phase, Natasha fearlessly works
                        to develop and elevate her approach and depth of
                        understanding. Her passion for collaboration extends
                        beyond the studio, where she connects locally, building
                        relationships with artists, suppliers and fabricators in
                        her constant pursuit of all things interesting, local
                        and crafted.
                    </p>
                </>
            ),
        },
    ],
}

export default about
