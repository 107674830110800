/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react"
import { ReactComponent as ThreeDots } from "../images/home-threedots.svg"
import { ReactComponent as BigLogoImg } from "../images/home-biglogo.svg"

import { css } from "@emotion/react"
import { CakeFrames } from "../layouts/Cake"
import Layer, { LayerZoomFlyIn, LayerScrollMarquee } from "../layouts/Layer"
import Main from "../layouts/Main"
import config from "../config"
import FullBleed from "../layouts/FullBleed"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import useIsMobile from "../hooks/Mobile"
const styles = {
    images: {
        maxHeight: "100vh",
        height: "100vh",
        maxWidth: "calc(100vw - " + config.margins.large + "*2)",
        margin: config.margins.large,
    },
}
export function BigDots(props) {
    return (
        <div>
            <ThreeDots css={css(styles.images)} />
        </div>
    )
}
function BigLogo(props) {
    return (
        <div>
            <BigLogoImg css={css(styles.images)} />
        </div>
    )
}

function FeatureProject(props) {
    const project = config.projects[config.featuredProject]
    return (
        <Layer
            fit
            caption={project.name}
            rightCaption={<span>{"FEATURED PROJECT"}</span>}
        >
            <Link to={"/projects/" + project.key}>
                <FullBleed image={project.mainImage} />
            </Link>
        </Layer>
    )
}

function AllProjects(props) {
    const projects = []
    for (const project of Object.values(config.projects)) {
        console.log(project)
        projects.push(
            <Layer key={project.key} fit caption={project.name}>
                <Link to={"/projects/" + project.key}>
                    <FullBleed image={project.mainImage} />
                </Link>
            </Layer>
        )
    }
    return <>{projects}</>
}

function Final(props) {
    const isMobile = useIsMobile()

    return (
        <>
            <Layer
                caption="INTERIOR DESIGN"
                rightCaption={isMobile ? "" : <span>&darr;{"LOOK DOWN"}</span>}
            >
                <motion.div
                    onViewportLeave={() => {
                        props.onBigLogoVisablity(false)
                    }}
                    onViewportEnter={() => {
                        props.onBigLogoVisablity(true)
                    }}
                >
                    <BigLogo />
                </motion.div>
            </Layer>
            {!isMobile && (
                <>
                    <FeatureProject />
                    <LayerScrollMarquee
                        rootUrl="/projects/"
                        items={Object.values(config.projects)}
                    />
                </>
            )}
            {isMobile && <AllProjects />}
        </>
    )
}

export default function Landing(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [bigLogoVisable, setBigLogoVisable] = useState(true)
    return (
        <CakeFrames>
            <Layer>
                <BigDots />
            </Layer>
            <Layer>
                <BigLogo />
            </Layer>
            <Main hideLogo={bigLogoVisable}>
                <Final
                    onBigLogoVisablity={(v) => {
                        setBigLogoVisable(v)
                    }}
                />
            </Main>
        </CakeFrames>
    )
}
