import projects from "./projects"
import about from "./about"
const config = {
    colors: {
        nude: "#E1D2C8",
        fawn: "#AA877D",
        night: "#283246",
        gray: "#CDCDCD",
        black: "#3C3C3C",
        white: "#FFFFFF",
    },
    margins: {
        xxlarge: "64px",
        xlarge: "32px",
        large: "16px",
        mid: "8px",
        small: "4px",
    },

    font: {
        xxlarge: "40pt",
        xlarge: "20pt",
        large: "14pt",
        mid: "12pt",
        small: "10pt",
    },
    featuredProject: "theBeachMotel",
    projects: projects,
    about: about,

    contact: {
        tagline: (
            <>
                <span>
                    a <b>creative practice</b> founded on a{" "}
                    <b>culture of collaboration</b>
                </span>
            </>
        ),
        email: "hello@commongoodstudio.ca",
        phone: "416.553.9448",
        phoneLink: "+1-416-553-9448",
    },
}

export default config
