/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react"

import { css } from "@emotion/react"
import { CakeSidways, CakeStack } from "../layouts/Cake"
import Layer from "../layouts/Layer"
import Main from "../layouts/Main"
import config from "../config"
import FullBleed from "../layouts/FullBleed"
import FaceCard from "../layouts/FaceCard"
import Contact from "./Contact"
import useIsMobile from "../hooks/Mobile"

export default function About(props) {
    const isMobile = useIsMobile()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const styles = {
        firstHit: {
            fontFamily: "thin",
            fontSize: isMobile ? config.font.xlarge : config.font.xxlarge,
            margin: "auto",
            marginTop: "calc(" + config.font.xxlarge + " * 3)",
            marginBottom: "calc(" + config.font.xxlarge + " * 3)",

            display: "flex",
            alignItems: "center",
            textAlign: "center",
            width: "80vw",
            "& b": {
                fontFamily: "bold",
            },
        },
        blurb: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: config.colors.black,
            paddingLeft: isMobile
                ? config.margins.xlarge
                : "calc(" + config.margins.xlarge + " * 4)",
            paddingRight: isMobile
                ? config.margins.xlarge
                : "calc(" + config.margins.xlarge + " * 4)",
            fontFamily: "regular",
            backgroundColor: config.colors.nude,
            height: "100%",
            margin: "auto",
        },
        blurbContiner: {
            width: "100vw",
        },
    }
    return (
        <Main>
            <CakeStack>
                <Layer fit>
                    <FullBleed
                        suspend={props.suspend}
                        scale={isMobile ? 1.2 : 1}
                        image={config.about.introImage}
                    />
                </Layer>
                <Layer fit>
                    <div css={css(styles.firstHit)}>
                        {config.about.firstHit}
                    </div>
                </Layer>

                <div css={css(styles.blurb)}>
                    <div css={css(styles.blurb)}>{config.about.blurb}</div>
                </div>
                <Contact embed />
            </CakeStack>
        </Main>
    )
}
