/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useRef, useState } from "react"
import { css } from "@emotion/react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import Image from "./Image"
import config from "../config"
export default function FullBleed(props) {
    const styles = {
        image: {
            height: "auto",
            width: props.half ? "50vw" : "100vw",
            margin: 0,
            verticalAlign: "top",
            transform: "scale(" + (props.scale ? props.scale : 1) + ")",
            transformOrigin: "top center",
        },
        container: {
            overflow: "hidden",
            margin: 0,
            padding: 0,
            marginBottom: props.marginBottom ? config.margins.large : 0,
        },
        phantom: {
            display: "none",
        },
    }

    const offset = 50

    const [elementTop, setElementTop] = useState(0)
    const [clientHeight, setClientHeight] = useState(0)
    const ref = useRef(null)

    const { scrollY } = useViewportScroll()
    const initial = elementTop - clientHeight
    const final = elementTop + offset
    const y = useTransform(scrollY, [initial, final], [-offset * 2, 0])
    useLayoutEffect(() => {
        const element = ref.current
        // save our layout measurements in a function in order to trigger
        // it both on mount and on resize
        const onResize = () => {
            // use getBoundingClientRect instead of offsetTop in order to
            // get the offset relative to the viewport
            setElementTop(
                element.getBoundingClientRect().top + window.scrollY ||
                    window.pageYOffset
            )
            setClientHeight(window.innerHeight)
        }
        onResize()
        window.addEventListener("resize", onResize)
        return () => window.removeEventListener("resize", onResize)
    }, [ref])

    return (
        <div css={css(styles.container)}>
            <motion.div
                ref={ref}
                style={props.animate ? { scale: 1.2, translateY: y } : {}}
                css={styles.image}
                src={props.image}
                alt="full"
            >
                <Image
                    suspend={props.suspend}
                    css={styles.image}
                    src={props.image}
                    alt="full"
                />
            </motion.div>
        </div>
    )
}
