/** @jsxImportSource @emotion/react */
import React from "react"

import { css } from "@emotion/react"
import { CakeStack } from "../layouts/Cake"
import Layer from "../layouts/Layer"
import config from "../config"
import { Dots } from "../layouts/FaceCard"
import Main from "../layouts/Main"
import useIsMobile from "../hooks/Mobile"

export default function Contact(props) {
    const isMobile = useIsMobile()
    const styles = {
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: isMobile ? "center" : "flex-end",
            height: "100%",
            width: "auto",
            paddingRight: config.margins.large,
            "& a": {
                textDecoration: "none",
                color: "black",
            },
        },
        caption: {
            fontFamily: "thin",
            fontSize: config.font.xxlarge,
            textAlign: isMobile ? "center" : "right",

            "& b": {
                fontFamily: "bold",
            },
        },
        info: { fontFamily: "bold", textAlign: "left" },
        infoContainer: {
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "row",
            alignItems: "center",
            gap: config.margins.large,
        },
        dotsContainer: {
            margin: isMobile ? config.margins.xlarge : 0,
        },
    }

    const cont = (
        <CakeStack>
            <Layer>
                <div css={css(styles.root)}>
                    <div css={css(styles.caption)}>
                        {config.contact.tagline}
                    </div>
                    <div css={css(styles.infoContainer)}>
                        <div css={css(styles.info)}>
                            <a href={"mailto:" + config.contact.email}>
                                {config.contact.email}
                            </a>
                        </div>
                        {!isMobile && <div css={css(styles.info)}> | </div>}
                        <div css={css(styles.info)}>
                            <a href={"tel:" + config.contact.phoneLink}>
                                {config.contact.phone}
                            </a>
                        </div>
                        <div css={css(styles.dotsContainer)}>
                            <Dots
                                color={config.colors.fawn}
                                horizontal
                                halfSize={!isMobile}
                            />
                        </div>
                    </div>
                </div>
            </Layer>
        </CakeStack>
    )
    if (props.embed) {
        return cont
    }
    return <Main>{cont}</Main>
}
