/** @jsxImportSource @emotion/react */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"

import { css } from "@emotion/react"
import { CakeStack } from "../layouts/Cake"
import Layer, { LayerImpact } from "../layouts/Layer"

import Main from "../layouts/Main"
import config from "../config"
import FullBleed from "../layouts/FullBleed"
import Columns from "../layouts/Columns"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import { Link, useParams } from "react-router-dom"
import DuoBleed from "../layouts/DuoBleed"
import { Dots } from "../layouts/FaceCard"
import Image from "../layouts/Image"
import ThreeSplit from "../layouts/ThreeSplit"

function Layers(props) {
    const project = props.project
    const items = []

    let i = 0
    for (const l of project.layouts) {
        i++
        if (l.type === "fullbleed") {
            items.push(
                <Layer fit key={i}>
                    <FullBleed
                        suspend={props.suspend}
                        viewportRef={props.viewportRef}
                        animate={l.animate}
                        image={l.images[0]}
                        marginBottom={l.marginBottom}
                        scale={l.scale}
                    />
                </Layer>
            )
        }
        if (l.type === "columns") {
            items.push(
                <Layer fit key={i}>
                    <Columns
                        suspend={props.suspend}
                        viewportRef={props.viewportRef}
                        marginBottom={l.marginBottom}
                        animate={l.animate}
                        images={l.images}
                    />
                </Layer>
            )
        }
        if (l.type === "duobleed") {
            items.push(
                <Layer fit key={i}>
                    <DuoBleed
                        suspend={props.suspend}
                        viewportRef={props.viewportRef}
                        marginBottom={l.marginBottom}
                        flip={l.flip}
                        animate={l.animate}
                        images={l.images}
                    />
                </Layer>
            )
        }
        if (l.type === "threesplit") {
            items.push(
                <Layer fit key={i}>
                    <ThreeSplit
                        suspend={props.suspend}
                        viewportRef={props.viewportRef}
                        marginBottom={l.marginBottom}
                        flip={l.flip}
                        animate={l.animate}
                        images={l.images}
                        text={l.text}
                    />
                </Layer>
            )
        }
    }
    return <>{items}</>
}

function NextPrevious(props) {
    const project = props.nextProject
    const styles = {
        root: {
            height: "70vh",
        },

        body: {
            margin: "auto",
            paddingTop: config.margins.xxlarge,

            paddingBottom: config.margins.xxlarge,
        },
        linkText: {
            fontFamily: "bold",
            fontSize: config.font.xxlarge,
            textTransform: "uppercase",
            paddingBottom: config.margins.xxlarge,
            color: config.colors.black,
            textAlign: "center",
            paddingLeft: config.margins.large,
            paddingRight: config.margins.large,
            marginTop: "calc(-25vh - " + config.font.large + ")",
            mixBlendMode: "difference",
        },
        anchor: {
            textDecoration: "none",
        },
        container: {
            display: "flex",
            justifyContent: "center",
            width: "50vh",
            height: "50vh",
            borderRadius: "50%",
            overflow: "hidden",
            margin: "auto",
            "&:hover": {
                overflow: "show",
            },
        },
        image: { width: "100%", objectFit: "cover" },
    }

    return (
        <Layer
            rightCaption={
                <Link css={css(styles.anchor)} to={"/projects/" + project.key}>
                    next project
                </Link>
            }
            title={project.name}
            fit
        >
            <div css={css(styles.root)}>
                <Link css={css(styles.anchor)} to={"/projects/" + project.key}>
                    <div css={css(styles.root)}>
                        <div css={css(styles.body)}>
                            <div css={css(styles.container)}>
                                <Image
                                    css={css(styles.image)}
                                    src={project.mainImage}
                                />
                            </div>
                            <div css={css(styles.linkText)}>
                                {/*project.name*/}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </Layer>
    )
}

export default function Project(props) {
    const { key } = useParams()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [key])
    const projects = Object.values(config.projects)
    const project = projects.find((p) => p.key === key)
    const index = projects.indexOf(project)
    let nextProject = null
    if (index >= projects.length - 1) {
        nextProject = projects[0]
    } else {
        nextProject = projects[index + 1]
    }
    return (
        <Main>
            <div css={css({ minHeight: "200vh" })}>
                <CakeStack>
                    <LayerImpact
                        viewportRef={props.viewportRef}
                        threeQuarter={!project.compact}
                        fit={project.compact}
                        compact={project.compact}
                        altStyle={project.compact}
                        title={project.name}
                        color={
                            project.compact
                                ? config.colors.nude
                                : config.colors.night
                        }
                        textColor={
                            project.compact
                                ? config.colors.black
                                : config.colors.nude
                        }
                    >
                        {project.descr}
                    </LayerImpact>
                    <Layers
                        suspend={props.suspend}
                        viewportRef={props.viewportRef}
                        project={project}
                    />
                    <Layer viewportRef={props.viewportRef} fit>
                        <div>&nbsp;</div>
                    </Layer>
                    <NextPrevious
                        suspend={props.suspend}
                        nextProject={nextProject}
                    />
                </CakeStack>
            </div>
        </Main>
    )
}
