/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react"
import config from "../config"
import { motion } from "framer-motion"
import useIsMobile from "../hooks/Mobile"
import Image from "./Image"

export default function DuoBleed(props) {
    const isMobile = useIsMobile()
    const styles = {
        root: {
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            height: "auto",
            width: "100vw",
            gap: isMobile ? 0 : config.margins.large,
            marginTop: config.margins.large,
            marginBottom: 0,
            justifyContent: "flex-start",
        },
        container: {
            height: isMobile ? "auto" : "95vh",
            marginTop: 0,
            overflow: "hidden",
            width: isMobile ? "100vw" : "33vw",
            marginBottom: props.marginBottom ? config.margins.large : 0,
            display: "flex",
            justifyContent: "center",
        },
        first: {
            width: isMobile ? "100vw" : "66vw",
            flexGrow: 2,
            marginBottom: isMobile ? config.margins.large : 0,
        },
        image: {
            height: isMobile
                ? "auto"
                : "calc(100vh - " + config.margins.large + " * 2)",
            width: isMobile ? "100vw" : "100%",
            verticalAlign: "top",
            objectFit: "cover",
        },
    }

    const items = []
    let i = 0
    const flipKey = props.flip ? 2 : 1
    for (const img of props.images) {
        i++
        items.push(
            <div
                key={i}
                css={
                    i === flipKey
                        ? css(styles.container, styles.first)
                        : css(styles.container)
                }
            >
                <motion.div
                    initial={
                        props.animate
                            ? { opacity: 0, scale: 0.8, translateY: 100 }
                            : {}
                    }
                    whileInView={{ opacity: 1, scale: 1, translateY: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 1, delay: i / 4 }}
                    alt=""
                >
                    <Image
                        suspend={props.suspend}
                        css={css(styles.image)}
                        src={img}
                        alt="full"
                    />
                </motion.div>
            </div>
        )
    }
    return <div css={css(styles.root)}>{items}</div>
}
