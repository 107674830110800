/** @jsxImportSource @emotion/react */
import React from "react"
import { motion } from "framer-motion"
import { css } from "@emotion/react"
import useIsMobile from "../hooks/Mobile"

export function CakeFrames(props) {
    const frameSeconds = props.frameSeconds ? props.frameSeconds : 2
    const fadeSeconds = props.fadeSeconds ? props.fadeSeconds : 2

    const variants = {
        hidden: (i) => ({
            opacity: i == 0 ? 1 : 0,
            display: i == props.children.length - 1 ? "none" : "block",
        }),
        visible: (i) => {
            return {
                opacity: 1,
                display: "block",
                transition: {
                    delay: i * frameSeconds,
                    duration: i == 0 ? 0.001 : fadeSeconds,
                },
            }
        },
    }
    const items = []

    for (const i in props.children) {
        items.push(
            <motion.div
                key={i}
                custom={i}
                initial="hidden"
                animate="visible"
                variants={variants}
                css={css({
                    position: "absolute",
                })}
            >
                {props.children[i]}
            </motion.div>
        )
    }

    return <CakeStack>{items}</CakeStack>
}
export function CakeStack(props) {
    const styles = { root: { display: "flex", flexDirection: "column" } }
    return <div css={css(styles.root)}>{props.children}</div>
}
export function CakeSidways(props) {
    const isMobile = useIsMobile()
    const styles = {
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            height: props.fit ? "auto" : "100%",

            "& > *": {
                flexGrow: 1,
            },
            //A hack to fix a flexbox issue
            backgroundColor: props.backgroundColor,
        },
    }
    if (isMobile) {
        return <CakeStack>{props.children}</CakeStack>
    }
    return <div css={css(styles.root)}>{props.children}</div>
}
