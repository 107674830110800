/** @jsxImportSource @emotion/react */
import React, { useState } from "react"
import { CakeFrames } from "../layouts/Cake"
import Layer from "../layouts/Layer"
import { BigDots } from "./Landing"
import { css } from "@emotion/react"
export default function Loading(props) {
    return (
        <div
            css={css({
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: "hidden",
                height: "100vw",
            })}
        >
            <CakeFrames>
                <Layer caption="LOADING...">
                    <BigDots />
                </Layer>
                <Layer caption="LOADING...">
                    <div> &nbsp;</div>
                </Layer>
                <Layer caption="LOADING...">
                    <BigDots />
                </Layer>
                <Layer caption="LOADING...">
                    <div> &nbsp;</div>
                </Layer>
                <Layer caption="LOADING...">
                    <BigDots />
                </Layer>
                <Layer caption="LOADING...">
                    <div> &nbsp;</div>
                </Layer>
                <Layer caption="LOADING...">
                    <BigDots />
                </Layer>
                <Layer caption="LOADING...">
                    <div> &nbsp;</div>
                </Layer>
                <Layer caption="LOADING...">
                    <BigDots />
                </Layer>
                <Layer caption="LOADING...">
                    <div> &nbsp;</div>
                </Layer>
            </CakeFrames>
            <div css={css({ overflow: "hidden", height: "100vw" })}>&nbsp;</div>
        </div>
    )
}
