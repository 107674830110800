/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react"
import config from "../config"
import { ReactComponent as HeaderLogo } from "../images/header-logo.svg"
import { AnimatePresence, motion } from "framer-motion"
import { Link } from "react-router-dom"
import useIsMobile from "../hooks/Mobile"

function useStyles(isMobile) {
    const styles = {
        root: {
            backgroundColor: config.colors.white,
            width: "100vw",
        },
        links: {
            position: "fixed",
            top: config.margins.large,
            right: config.margins.large,
            fontFamily: "regular",
            fontSize: config.font.large,
            zIndex: "999",
            mixBlendMode: "difference",
            color: config.colors.black,

            "& a": {
                textDecoration: "none",
                color: config.colors.black,
                filter: "invert(1)  contrast(400%) grayscale(100%)",
            },
        },
        logo: {
            position: "fixed",
            color: config.colors.black,

            top: config.margins.large,
            left: config.margins.large,
            height: config.font.large,
            marginRight: config.margins.large,
            zIndex: "999",
            transition: "visibility 2s",
            mixBlendMode: "difference",
            "& svg": {
                color: config.colors.black,
                filter: "invert(1) contrast(400%) grayscale(100%)",
            },
        },
    }
    if (isMobile) {
        delete styles.logo.top
        styles.logo.bottom = config.margins.large
        styles.logo.width = "100vw"
        styles.logo.left = 0
    }
    return styles
}

function Links(props) {
    const styles = useStyles(props.isMobile)
    return (
        <div css={css(styles.links)}>
            <Link to="/about">ABOUT</Link> | <Link to="/contact">CONTACT</Link>
        </div>
    )
}

function Logo(props) {
    const styles = useStyles(props.isMobile)
    return (
        <Link to="/">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                css={css(styles.logo)}
            >
                <HeaderLogo isMobile={props.isMobile} height="16pt" />
            </motion.div>
        </Link>
    )
}
export default function Main(props) {
    const isMobile = useIsMobile()
    const styles = useStyles(isMobile)

    return (
        <div css={css(styles.root)}>
            <Links isMobile={isMobile} />
            <AnimatePresence>
                {!props.hideLogo && <Logo isMobile={isMobile} />}
            </AnimatePresence>
            {props.children}
        </div>
    )
}
