/** @jsxImportSource @emotion/react */
import React, {
    useRef,
    useState,
    useLayoutEffect,
    useCallback,
    useEffect,
} from "react"
import { Link } from "react-router-dom"
import { css } from "@emotion/react"
import config from "../config"
import {
    AnimatePresence,
    motion,
    useElementScroll,
    useSpring,
    useTransform,
} from "framer-motion"
import useIsMobile from "../hooks/Mobile"

export default function Layer(props) {
    let color = props.color ? props.color : config.colors.nude
    const height = props.fit ? "auto" : props.threeQuarter ? "80vh" : "100vh"
    const isMobile = useIsMobile()
    const styles = {
        root: {
            backgroundColor: color,
            width: "100vw",
            position: "relative",
            maxHeight: height,
            height: height,
            padding: 0,
            margin: 0,
        },
        title: {
            fontFamily: "bold",
            fontSize: isMobile ? config.font.xlarge : config.font.xxlarge,
            position: "absolute",
            top:
                "calc( 50% - " +
                (isMobile ? config.font.xlarge : config.font.xxlarge) +
                ")",
            left: 0,
            textTransform: "uppercase",
            mixBlendMode: "difference",
            width: "100%",
            filter: "invert(100%)  contrast(300%) grayscale(100%)",
            textAlign: "center",
            pointerEvents: "none",
        },
        caption: {
            fontFamily: "regular",
            fontSize: config.font.large,
            position: "absolute",
            bottom: config.margins.large,
            left: config.margins.large,
            textTransform: "uppercase",
            //mixBlendMode: "difference",
            //filter: "invert(1) ",
        },
        rightCaption: {
            fontFamily: "regular",
            fontSize: config.font.large,
            position: "absolute",
            bottom: config.margins.large,
            right: config.margins.large,
            textTransform: "uppercase",
            mixBlendMode: "difference",
            filter: "invert(100%)  contrast(300%) grayscale(100%)",
        },
    }
    return (
        <div css={css(styles.root)}>
            {props.children}
            {props.title && <div css={styles.title}>{props.title}</div>}
            {props.caption && <div css={styles.caption}>{props.caption}</div>}
            {props.rightCaption && (
                <div css={styles.rightCaption}>{props.rightCaption}</div>
            )}
        </div>
    )
}

export function LayerImpact(props) {
    const height = props.fit ? "auto" : props.threeQuarter ? "80vh" : "100vh"

    let textColor = props.textColor ? props.textColor : config.colors.black
    const styles = {
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: height,
            width: "100vw",
            color: textColor,
        },
        title: {
            display: "flex",
            flexGrow: "2",
            height: props.compact ? "auto" : "100vw",
            fontFamily: "bold",
            textTransform: "uppercase",
            fontSize: config.font.xxlarge,
            margin: "auto",
            padding: config.margins.large,
            alignItems: "center",
            textAlign: "center",
        },
        content: {
            flexGrow: "1",
            margin: config.margins.large,
            textAlign: "center",
            fontFamily: "regular",
        },
    }
    return (
        <Layer {...props} title={null}>
            <div css={css(styles.root)}>
                <div css={css(styles.title)}>{props.title}</div>
                <div css={css(styles.content)}>{props.children}</div>
            </div>
        </Layer>
    )
}

export function LayerZoomFlyIn(props) {
    let scale = [10, 1]
    if (props.bouceBack) {
        scale = [0.0000001, 2, 1]
    }
    return (
        <Layer>
            <motion.div
                animate={{
                    scale: scale,
                }}
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 5,
                    mass: 1,
                }}
            >
                {props.children}
            </motion.div>
        </Layer>
    )
}

export function LayerScrollMarquee(props) {
    let color = config.colors.nude

    const ref = useRef()
    const contentRef = useRef()

    const { scrollYProgress } = useElementScroll(ref)
    const [readyScroll, setReadyScroll] = useState(false)
    const [currentSelection, setCurrenSelection] = useState(props.items[0])

    const transform = useTransform(
        scrollYProgress,
        [0, 1],
        [0, contentRef.current ? -contentRef.current.offsetWidth : 0]
    )
    useEffect(() => {
        if (readyScroll) {
            contentRef.current.focus()
            document.body.style = { overflow: "hidden" }
        } else {
            document.body.focus()
            document.body.style = { overflow: "scroll" }
        }
        return () => {
            document.body.style = { overflow: "scroll" }
        }
    }, [readyScroll])

    useEffect(
        () =>
            scrollYProgress.onChange((latest) => {
                if (latest === 0 && readyScroll) {
                    setReadyScroll(false)
                }
                const inc = 1 / props.items.length

                let val = Math.floor(latest / (inc * 1.1))
                if (val > props.items.length - 1) {
                    val = props.items.length - 1
                }
                if (props.items[val]) {
                    setCurrenSelection(props.items[val])
                }
            }),
        []
    )
    //const physics = { damping: 15, mass: 1, stiffness: 55 }
    //const spring = useSpring(transform, physics)
    const spring = transform
    const styles = {
        root: {
            height: "100vh",
            width: "100vw",
            overflowY: readyScroll ? "scroll" : "hidden",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },
            pointerEvents: "auto",
            overflowX: "hidden",
        },
        vScroll: {
            top: 0.1,
            right: "100vw",
            left: "0",
            width: "350vw",

            height: props.items.length * 1000 + "pt",
            overflow: "scroll",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            zIndex: 6,
            backgroundColor: config.colors.nude,
            pointerEvents: "none",
        },
        content: {
            position: "absolute",
            top: "30vh",
            left: "0vw",
            width: "100vw",
            paddingLeft: "0",
            paddingRight: "50vw",
            zIndex: 7,
            // mixBlendMode: "diffrence",
            color: config.colors.black,
            pointerEvents: "none",
            //filter: "invert(1)  contrast(400%) grayscale(100%)",
            webkitTextStroke: "1px " + config.colors.nude,
        },
        imageContent: {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: "15vh",
            width: "100vw",
            bottom: "15vh",
            paddingLeft: "0",
            zIndex: 6,
            overflow: "hidden",
            overscrollBehavior: "none",
            pointerEvents: "none",
            // mixBlendMode: "diffrence",
        },
        image: {
            height: "100%",
            width: "auto",
            overflow: "hidden",
            margins: "auto",
            pointerEvents: "none",
            objectFit: "cover",
            zIndex: 1,
        },
        label: {
            display: "inline-block",

            textTransform: "uppercase",
            fontSize: config.font.xxlarge,
            color: "black",
            fontFamily: "bold",
            zIndex: 7,
            pointerEvents: "none",
            width: "40vw",
        },
        labelUnactive: {
            display: "inline-block",
            textTransform: "uppercase",
            fontSize: config.font.xxlarge,
            color: "black",
            fontFamily: "bold",
            pointerEvents: "none",
            mixBlendMode: "normal",
            opacity: 0.25,
            filter: "none",
            width: "40vw",
        },
        divider: {
            textTransform: "uppercase",
            fontSize: config.font.xxlarge,
            color: "black",
            fontFamily: "thin",
            padding: config.margins.large,
        },
        pad: {
            display: "inline-block",
            width: "50vw",
        },
    }
    const labels = [<div css={css(styles.pad)}></div>]
    for (const p of props.items) {
        let style = styles.labelUnactive

        if (p === currentSelection) {
            style = styles.label
        }
        labels.push(
            <span key={"one-" + p.key} css={css(style)}>
                {p.name}
            </span>
        )
        labels.push(
            <span key={"divider-" + p.key} css={css(styles.divider)}>
                {" "}
            </span>
        )
    }
    labels.pop()
    return (
        <Layer>
            <div ref={ref} css={styles.root}>
                <Link to={props.rootUrl + currentSelection.key}>
                    <div ref={contentRef} css={css(styles.vScroll)}>
                        <AnimatePresence>
                            <motion.div
                                key={currentSelection.name}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                css={css(styles.imageContent)}
                            >
                                <img
                                    src={
                                        currentSelection.altImage
                                            ? currentSelection.altImage
                                            : currentSelection.mainImage
                                    }
                                    css={css(styles.image)}
                                    alt="project"
                                />
                                <motion.div
                                    css={css(styles.content)}
                                    style={{ x: spring }}
                                >
                                    {labels}
                                </motion.div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </Link>
            </div>
            <motion.div
                style={{
                    width: "100vw",
                    height: "1px",
                    backgroundColor: "red",
                }}
                onViewportLeave={() => {
                    setReadyScroll(false)
                }}
                onViewportEnter={() => {
                    setReadyScroll(true)
                }}
            ></motion.div>
        </Layer>
    )
}
